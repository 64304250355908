@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
  --today-color: #C4DBF2;
}

.customCalendar {
  position: relative;
  overflow: hidden;
  border-radius: 7px;

  .extra {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--neutral-color);
    position: relative;
    padding: 10px;
  }

  .icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    padding-top: 0;
  }

  .flex {
    display: flex;
  }

  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .row-middle {
    align-items: center;
  }

  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-center {
  }

  .col-start {
    justify-content: flex-start;
    text-align: left;
  }

  .col-center {
    justify-content: center;
    text-align: center;
  }

  .col-end {
    justify-content: flex-end;
    text-align: right;
  }

  .calendar {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
  }

  .calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
  }

  .calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
  }

  .calendar .header .icon:hover {
    transform: scale(1.75);
    transition: .25s ease-out;
    color: var(--main-color);
  }

  .calendar .header .icon:first-of-type {
    margin-left: 1em;
  }

  .calendar .header .icon:last-of-type {
    margin-right: 1em;
  }

  .calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid var(--border-color);
  }

  .calendar .extra .cell {
    position: relative;
    height: 5em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
  }

  .calendar .extra .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
  }

  .calendar .extra .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
    border-image-slice: 1;

    .number {
      opacity: 0;
    }
  }


  .calendar .extra .row {
    border-bottom: 1px solid var(--border-color);
  }

  .calendar .extra .row:last-child {
    border-bottom: none;
  }

  .calendar .extra .cell:last-child {
    border-right: none;
  }

  .calendar .extra .cell .number {
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    transition: all ease 0.5s;
    font-weight: 700;
  }

  .calendar .extra .disabled {
    pointer-events: none;

    &:before, &:after {
      display: none;
    }

    * {
      color: var(--text-color-light) !important;
    }
  }

  .calendar .extra .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 3em;
    position: absolute;
    top: -.1em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
    right: -1.2em;
  }

  .calendar .extra .cell:hover .bg,
  .calendar .extra .selected .bg {
    opacity: 0.5;
    transition: .5s ease-in;
    right: 0;
  }

  .calendar .extra .event {
    position: relative;

    &:before {
      content: "";
      height: 3px;
      background-color: var(--main-color);
      width: 30%;
      position: absolute;
      bottom: 6px;
      left: 6px;
    }

    &:after {
      content: "";
      height: 30%;
      background-color: var(--main-color);
      width: 3px;
      position: absolute;
      bottom: 6px;
      left: 6px;
    }
  }

  .calendar .extra .cell:hover .number {
    opacity: 0;
  }

  .calendar .extra .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
    padding: 5px;
  }

  .days p {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin: 0;
    color: #003A65;
    padding-left: 10px;
    text-align: left;

    &:nth-last-child(-n+2) {
      color: red;
    }
  }

    .extra .row .cell:nth-last-child(-n+2) * {
    color: red;
  }

  .extra .row .cell:nth-last-child(-n+2).selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(
                    45deg, #f6020291 0%, rgb(255 7 7 / 25%) 40%);
    border-image-slice: 1;
  }


  .calendar .extra .today {
    background-color: var(--today-color);
    border: none;

    .bg {
      right: 0;
      opacity: 1;
    }

    * {
      color: #FFFFFF;
    }
  }
}