.container{
    width: 1500px;
}

.texts{
    margin-left: 130px;
    margin-right: 125px;
}

.text1{
    font-family: Vollkorn;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 48px;
    letter-spacing: 0.0432em;
    color: #242424;
    margin-bottom: 20px;
}

.text2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #000000;
}

.text3a{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.0216em;
    color: #000000;
    width: 100%;
}

.t1{
    margin-top: 70px;
}

.t2{
    margin-top: 150px;
}

.t3{
    margin-top: 100px;
}

.t4{
    margin-bottom: 200px;
}

.t5{
    margin-top: -2%;
}

.t6{
    margin-top: 70px;
}