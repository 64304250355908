.slick-slider {
  .slick-slide {
    width: 30% !important;
    margin: 0 5px !important;
    overflow: hidden;
  }
}

.artisans-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
