.shop_cart_wrapper{
    .bread{
        margin-bottom: 56px;
        p{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.1em;
            color: #9F9F9F;
            span{
                color: black;
                font-weight: bold;
            }
        }
    }
    .table_holder, .table_card{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 24px;
        &.active{
            display: none;
        }
        span{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #000000;
        }
        .product{
            width: 35%;
            text-align: center;
        }
        .price{
            width: 16%;
            text-align: center;
        }
        .id{
            width: 16%;
            text-align: center;
        }
        .date{
            width: 16%;
            text-align: center;
        }
        .info{
            width: 16%;
            text-align: center;
        }
    }

    .codes_total{
        display: flex;
        justify-content: space-between;
        margin-top: 64px;
        // .discount_code{
        //     h2{
        //         font-family: Montserrat;
        //         font-style: normal;
        //         font-weight: 600;
        //         font-size: 30px;
        //         line-height: 40px;
        //         letter-spacing: 0.1em;
        //         color: #000000;
        //         margin-bottom: 34px;
        //     }
        //     .code_enter{
        //         border: 1px solid #474747;
        //         box-sizing: border-box;
        //         border-radius: 8px;
        //         overflow: hidden;
        //         display: flex;
        //         align-items: center;
        //         input{
        //             font-family: Montserrat;
        //             font-style: normal;
        //             font-weight: 300;
        //             font-size: 18px;
        //             line-height: 36px;
        //             letter-spacing: 0.0216em;
        //             color: #292929;
        //             padding: 0 0 0 35px;
        //             width: 400px;
        //             border: none;
        //             outline: none;
        //         }
        //         button{
        //             padding: 21px 35px;
        //             font-family: Montserrat;
        //             font-style: normal;
        //             font-weight: 600;
        //             font-size: 20px;
        //             line-height: 30px;
        //             letter-spacing: 0.05em;
        //             text-transform: uppercase;
        //             color: #FFFFFF;
        //             border: none;
        //             background-color: #474747;
        //             display: block;
        //         }
        //     }
        // }
        .cart_total{
            width: 100%;
            margin-bottom: 100px;
            display: flex;
            justify-content: flex-end;
            .first{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 26px;
            }
            h3{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 40px;
                letter-spacing: 0.1em;
                color: #000000;
                margin-bottom: 42px;
            }
            span{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.1em;
                color: #000000;
                &:nth-child(2){
                    color: #FF7575;
                    font-size: 30px;
                    line-height: 40px;
                }
            }
            .button{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #000000;
                padding: 10px 30px;
                border: none;
                border-radius: 8px;
                background-color: transparent;
                border: 1px solid #000000;
                margin-top: 15px;
            }

            .right{
                display: flex;
                justify-content: flex-end;
            }
        }

    }

    .empty_shop{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 100px 0;
        img{
            width: 136px;
            height: 136px;
            object-fit: contain;
            margin-bottom: 12px;
        }
        h1{
            font-family: Vollkorn;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 33px;
            letter-spacing: 0.425em;
            margin-bottom: 41px;
            color: #8A8A8A;
        }
        .link{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.05em;
            color: #000000;
            display: block;
            padding: 12px 34px;
            border: 2px solid #000000;
            border-radius: 8px;
        }
    }
}


.shop_mobile_card {
    background: red;
}



@media only screen and (max-width:1100px) {


.shop_cart_wrapper .codes_total .discount_code {
    width: 45%;
}

.shop_cart_wrapper .codes_total .cart_total  {
    width: 30%;
}

.shop_cart_wrapper .codes_total .discount_code .code_enter input {
    padding: 0 0 0 15px;
}

.customer_table .table_card .date {
    display: flex;
    justify-content: center;
    margin: bottom;
    margin-bottom: 20px;
}

}


@media only screen and (max-width:750px) {

    .shop_cart_wrapper .codes_total{
        flex-direction: column;
    }

    .shop_cart_wrapper .codes_total .discount_code {
        width: 100%;
    }

     .shop_cart_wrapper .codes_total .cart_total {
        width: 100%;
        margin-top: 32px;
    }

    .shop_cart_wrapper .codes_total .cart_total .right {
        justify-content: center;
    }

    
}


@media only screen and (max-width:450px) {

    .shop_cart_wrapper .codes_total .discount_code .code_enter input {
    padding:5px;
    font-size: 16px;
}

.shop_cart_wrapper .codes_total .discount_code .code_enter button {
    font-size: 16px;
}




.shop_cart_wrapper .table_holder, .shop_cart_wrapper .table_card {
    display: none;
}

.shop_cart_wrapper .bread span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #9F9F9F;
}

.shop_cart_wrapper .empty_shop h1 {
    text-align: center;
    font-size: 18px;
}
}


@media screen and (max-width: '1100px') {
    .shop_cart_wrapper{
        .table_card, .table_holder{
            .price{
                width: 14%;
            }
            .id{
                width: 14%;
            }
            .date{
                width: 10%;
                &.another{
                    width: 5%;
                }
            }
        }
    }
}

