.read-more-link {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: #000000;
  text-decoration: underline;
  border: none;
  background-color: transparent;
}

.card_two_carousel {
  display: none;
}

.platform-card_desktop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.platform-card_tablet {
  display: none;
}

.cards_desktop {
  width: 28% !important;
}

.cards_caro_slider_btn_block {
  display: none;
}

.owl-carousel.owl-drag .owl-item {
  background-color: white !important;
}

.founding_opened {
  display: none;
}

.home-wrapper {
  .banner {
    img {
      width: 100%;
      height: 790px;
      object-fit: cover;
    }
  }

  .inner-one {
    margin-top: 85px;
    position: relative;
    overflow: hidden;

    .add-block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .inner-two {
      .economy {
        display: flex;
        justify-content: flex-end;
      }

      .left-side-holder {
        font-family: Vollkorn;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 50px;
        letter-spacing: 0.4em;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 60px;
        text-align: center;

        &.another {
          position: relative;

          &::before {
            position: absolute;
            content: "";
            width: 1000%;
            height: 2px;
            background-color: #000;
            bottom: 16px;
            right: 99%;
          }
        }
      }
    }

    &.second {
      flex-direction: row-reverse;

      &::before {
        background: linear-gradient(to left, transparent 33%, #000 30% 100%);
      }
    }

    .left-side {
      width: 48%;

      .left-side-holder {
        font-family: Vollkorn;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 50px;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 60px;
        position: relative;
        display: inline-block;

        &::before {
          position: absolute;
          content: "";
          width: 1000%;
          height: 2px;
          background-color: #000;
          bottom: 16px;
          left: 97%;
        }
      }

      .left-side-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 30px;
      }
    }

    .right-side {
      width: 45%;
      position: relative;

      img {
        width: 100%;
        height: 750px;
        object-fit: cover;
      }
    }
  }

  .banner-two {
    margin-top: 85px;
    height: 400px;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.second {
      height: auto;

      img {
        height: 117px;
        width: 100%;
        object-fit: cover;
      }
    }

    &.third {
      img {
        height: 369px;
        width: 100%;
        object-fit: cover;
      }
    }

    &.four {
      img {
        height: 399px;
        width: 100%;
        object-fit: cover;
      }

      position: relative;
      height: auto;

      &::before {
        position: absolute;
        content: "PARTNERS";
        bottom: 73px;
        right: 163px;
        font-family: Vollkorn;
        font-style: normal;
        font-weight: bold;
        font-size: 120px;
        line-height: 167px;
        letter-spacing: 0.4em;
        text-transform: uppercase;
        color: #fffdfd;
      }
    }

    &.five {
      position: relative;
      height: auto;

      &::before {
        position: absolute;
        content: "ARTISANS";
        bottom: 73px;
        right: 163px;
        font-family: Vollkorn;
        font-style: normal;
        font-weight: bold;
        font-size: 120px;
        line-height: 167px;
        letter-spacing: 0.4em;
        text-transform: uppercase;
        color: #fffdfd;
      }

      img {
        height: 600px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .founders {
    position: relative;
    overflow: hidden;

    &::before {
      position: absolute;
      bottom: 73px;
      right: 200px;
      content: "FOUNDERS";
      font-family: Vollkorn;
      font-style: normal;
      font-weight: bold;
      font-size: 120px;
      line-height: 167px;
      letter-spacing: 0.4em;
      text-transform: uppercase;
      color: #fffdfd;
    }

    .founder-holder {
      margin: 73px 0;

      .founder-holder-text {
        font-family: Vollkorn;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 50px;
        letter-spacing: 0.4em;
        text-transform: uppercase;
        color: #000000;
        display: inline-block;
        position: relative;

        &::before {
          position: absolute;
          content: "";
          width: 1000%;
          height: 2px;
          background-color: #000;
          bottom: 16px;
          left: 97%;
        }
      }
    }

    img {
      height: 739px;
      width: 100%;
      object-fit: cover;
    }
  }

  .our-council {
    margin: 110px 0;
    overflow: hidden;

    .inner-div {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .card {
        width: 100%;
        text-align: center;
      }
    }
  }

  .our-team {
    overflow: hidden;

    .inner {
      .holder {
        margin-bottom: 58px;

        h1 {
          font-family: Vollkorn;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 50px;
          letter-spacing: 0.4em;
          text-transform: uppercase;
          color: #000000;
          position: relative;
          display: inline-block;

          &::before {
            position: absolute;
            bottom: 16px;
            width: 1000%;
            height: 2px;
            background-color: #000;
            content: "";
            left: 97%;
          }
        }
      }

      .card-two {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        text-align: center;
        flex-wrap: wrap;

        .inner-card {
          width: 20%;
        }
      }
    }
  }

  .support-finding {
    margin-top: 80px;
    margin-bottom: 100px;
    overflow: hidden;

    .inner {
      .holder {
        h1 {
          font-family: Vollkorn;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 50px;
          letter-spacing: 0.4em;
          text-transform: uppercase;
          color: #000000;
          position: relative;
          display: inline-block;

          &::before {
            position: absolute;
            bottom: 16px;
            width: 1000%;
            height: 2px;
            background-color: #000;
            content: "";
            left: 96%;
          }
        }

        margin-bottom: 56px;
      }

      .card-three {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 172px;
        text-align: center;

        @media screen and (max-width: 800px) {
          gap: 80px;
        }

        @media only screen and (max-width: 550px) {
          justify-content: center;
          flex-wrap: wrap;
        }

        .card {
          max-width: 246px;
          width: 100%;

          @media screen and (max-width: 800px) {
            max-width: 300px;
          }
        }
      }
    }
  }

  .founding-artisans {
    margin-bottom: 70px;
    overflow: hidden;

    .inner {
      .holder {
        margin: 73px 0 59px 0;

        h1 {
          font-family: Vollkorn;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 50px;
          letter-spacing: 0.4em;
          text-transform: uppercase;
          color: #000000;
          position: relative;
          display: inline-block;

          &::before {
            position: absolute;
            bottom: 16px;
            width: 1000%;
            height: 2px;
            background-color: #000;
            content: "";
            left: 96%;
          }
        }
      }

      .last-contents {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p {
          width: 95%;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 30px;
          color: #000000;

          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }

  .how-to-platform {
    margin: 100px 0;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 4px;
      background-color: #000;
      top: 0;
      bottom: 100px;
      margin: auto;
      display: none;
    }

    .inner {
      position: relative;

      .holder {
        margin-bottom: 110px;
        display: flex;
        justify-content: flex-end;

        .text {
          font-family: Vollkorn;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 50px;
          letter-spacing: 0.3em;
          text-transform: uppercase;
          color: #000000;
          position: relative;

          &::before {
            position: absolute;
            content: "";
            width: 1000%;
            height: 2px;
            background-color: #000;
            bottom: 16px;
            right: 99%;
          }
        }
      }

      .platform-card {
        width: 100%;

        .cards {
          width: 100%;
          position: relative;
          text-align: center;
          background-color: white !important;

          &::before {
            position: absolute;
            content: "";
            background-image: url("../../assets/icons/dots.png");
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            left: -30%;
            top: 150px;
          }

          &:nth-child(1) {
            &::before {
              display: none;
            }
          }

          img {
            border-radius: 50%;
            width: 350px;
            height: 350px;
            object-fit: cover;
            margin-bottom: 73px;
            transform: scale(0.9);
            transition: 0.2s;
            margin: auto;

            &:hover {
              transform: scale(1);
            }
          }

          h2 {
            font-family: Vollkorn;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 24px;
          }

          p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            color: #000000;
            text-align: justify;
          }
        }
      }

      .change-page {
        position: absolute;
        right: 0;
        bottom: -50px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: #000000;
        text-decoration: underline;
        border: none;
        background-color: transparent;
      }
    }
  }
}

.owl-carousel .owl-item {
  background-color: white !important;
  border: none !important;

  &.active {
    background-color: white !important;
  }
}

.inner_div_btn_block {
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  .inner_div_btn_block_bt {
    border: none;
    background: none;
    width: 72px;
    padding: 5px;
    border-bottom: 1px solid black;
    margin: 0 16px;
    position: relative;
    padding: 10px;

    img {
      width: 20px;
      position: absolute;
    }

    .inner_div_btn_block_icon_prev {
      left: 0;
      top: -15px;
      bottom: 0;
      margin: auto;
    }

    .inner_div_btn_block_icon_next {
      right: 0;
      top: -15px;
      bottom: 0;
      margin: auto;
    }
  }
}

@media screen and (max-width: 1400px) {
  .home-wrapper {
    .inner-one {
      .left-side {
        .left-side-holder {
          font-size: 32px;
          margin-bottom: 30px;
        }

        .left-side-text {
          font-size: 16px;
        }
      }

      .right-side {
        img {
          height: 600px;
        }
      }
    }

    .how-to-platform {
      .inner {
        .holder {
          margin-bottom: 80px;

          .text {
            font-size: 32px;
          }
        }

        .platform-card {
          .cards {
            img {
              width: 300px;
              height: 300px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .home-wrapper .inner-one .left-side .left-side-holder {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .how-to-platform .inner .holder .text {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .inner-one .left-side .left-side-text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.0216em;
    color: #000000;
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }

  .home-wrapper .how-to-platform .inner .change-page {
    font-size: 24px;
  }

  .read-more-link {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #000000;
  }

  .home-wrapper .inner-one .inner-two .left-side-holder {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .founders .founder-holder .founder-holder-text {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .our-team .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .support-finding .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .founding-artisans .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .founding-artisans .inner .last-contents p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.0216em;
    color: #000000;
    width: 95%;
  }

  .owl-carousel .owl-item {
    background-color: white !important;
    border: none !important;

    &.active {
      background-color: white !important;
    }
  }

  .home-wrapper .inner-one .right-side img {
    height: 820px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .home-wrapper .banner img {
    height: 790px;
  }

  .inner_div_btn_block {
    display: flex;
    margin-top: 38px;
  }

  .home-wrapper .inner-one .left-side .left-side-holder {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .inner-one .add-block {
    flex-direction: column;
  }

  .home-wrapper .inner-one .left-side {
    width: 100%;
  }

  .home-wrapper .inner-one .right-side {
    width: 100%;
  }

  .home-wrapper .inner-one .right-side img {
    height: 800px !important;
  }

  .home-wrapper .inner-one .left-side .left-side-text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.0216em;
    color: #000000;
  }

  .home-wrapper .how-to-platform .inner .holder .text {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards::before {
    display: none;
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards img {
    width: 280px;
    height: 280px;
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }

  .home-wrapper .inner-one .inner-two .left-side-holder {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .read-more-link {
    margin-bottom: 43px;
    display: block;
  }

  .home-wrapper .founders .founder-holder .founder-holder-text {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .founders::before {
    font-style: normal;
    font-weight: 700;
    font-size: 104px;
    line-height: 145px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #fffdfd;
    right: 0;
  }

  .council-wrapper .contents h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    height: 50px;
  }

  .home-wrapper .our-team .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .banner-two.four::before {
    font-style: normal;
    font-weight: 700;
    font-size: 104px;
    line-height: 145px;
    letter-spacing: 0.3em;
    right: 0;
    text-transform: uppercase;
    color: #fffdfd;
  }

  .home-wrapper .support-finding .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .banner-two.five::before {
    font-style: normal;
    font-weight: 700;
    font-size: 104px;
    line-height: 145px;
    letter-spacing: 0.3em;
    right: 0;
    text-transform: uppercase;
    color: #fffdfd;
  }

  .home-wrapper .founding-artisans .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .founding-artisans .inner .last-contents p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.0216em;
    color: #000000;
  }

  .home-wrapper .our-team .inner .card-two .inner-card {
    width: 45%;
    margin-bottom: 40px;
  }

  .owl-carousel .owl-item {
    background-color: white !important;
    border: none !important;

    &.active {
      background-color: white !important;
    }
  }

  .home-wrapper .our-council .inner-div .card {
    width: 100%;
  }

  .platform-card_desktop {
    display: none;
  }

  .platform-card_tablet {
    display: block;
  }
}

@media only screen and (max-width: 700px) {
  .home-wrapper {
    margin-top: 50px;
  }

  .home-wrapper .banner img {
    height: 467px !important;
  }

  .home-wrapper .inner-one .left-side .left-side-holder {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
    max-width: 340px;
    text-align: center;
  }

  .home-wrapper .inner-one .left-side .left-side-holder::before {
    display: none;
  }

  .home-wrapper .inner-one .left-side .left-side-text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .home-wrapper .inner-one .right-side img {
    height: 539px !important;
  }

  .home-wrapper .how-to-platform .inner .holder .text {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .how-to-platform .inner .holder .text::before {
    display: none;
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
    margin-top: 15px;
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 40px !important;
  }

  .owl-theme .owl-dots .owl-dot.active span {
    transform: scale(2);
  }

  .owl-theme .owl-dots .owl-dot.active + button span {
    transform: scale(1.5);
    background: #c4c4c4 !important;
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 56px !important;
  }

  .owl-carousel .owl-item {
    background-color: white !important;
    border: none !important;

    &.active {
      background-color: white !important;
    }
  }

  .home-wrapper .how-to-platform .inner .change-page {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #000000;
  }

  .home-wrapper .inner-one .inner-two .left-side-holder {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .inner-one .inner-two .left-side-holder.another::before {
    display: none;
  }

  .home-wrapper .founders .founder-holder .founder-holder-text {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .founders .founder-holder .founder-holder-text::before {
    display: none;
  }

  .home-wrapper .founders::before {
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    line-height: 100px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #fffdfd;
    bottom: 15px;
  }

  .home-wrapper .founders img {
    height: 266px;
    object-fit: contain;
  }

  .home-wrapper .our-team .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .our-team .inner .holder h1::before {
    display: none;
  }

  .home-wrapper .banner-two.four::before {
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    line-height: 100px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #fffdfd;
    bottom: 15px;
  }

  .home-wrapper .banner-two.four img {
    height: 266px;
    object-fit: contain;
  }

  .home-wrapper .support-finding .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .support-finding .inner .holder h1::before {
    display: none;
  }

  .read-more-link {
    padding-bottom: 5px;
  }

  .home-wrapper .banner-two.five::before {
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    line-height: 100px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #fffdfd;
    bottom: 15px;
  }

  .home-wrapper .banner-two.five img {
    height: 266px;
  }

  .home-wrapper .founding-artisans .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .founding-artisans .inner .holder h1::before {
    display: none;
  }

  .home-wrapper .founding-artisans .inner .last-contents p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.0216em;
    color: #000000;
    width: 100%;
    margin-bottom: 30px;
  }

  .home-wrapper .founding-artisans .inner .last-contents {
    flex-direction: column;
    height: 607px;
    overflow: hidden;

    &.opened {
      height: max-content;
      overflow: auto;
    }
  }

  .founding_opened {
    display: block;
    border: none;
    background: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #000000;
    padding-bottom: 5px;
    border-bottom: 2px solid black;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .home-wrapper .banner-two.four img {
    height: 266px;
  }

  .home-wrapper .support-finding .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 39px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .banner-two.five img {
    height: 266px !important;
  }

  .home-wrapper .founding-artisans .inner .last-contents p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.0216em;
    color: #000000;
  }
}

@media only screen and (max-width: 450px) {
  .home-wrapper .how-to-platform .inner .platform-card .cards {
    margin: auto;
    width: 100%;
    background: white;
  }

  .home-wrapper .support-finding .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .founding-artisans .inner .holder {
    text-align: center;
  }

  .home-wrapper .support-finding .inner .holder {
    text-align: center;
  }

  .home-wrapper .our-team .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin: auto;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .our-team .inner .holder {
    text-align: center;
  }

  .owl-theme .owl-dots .owl-dot.active span {
    transform: scale(2);
  }

  .owl-theme .owl-dots .owl-dot.active + button span {
    transform: scale(1.5);
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 56px !important;
  }

  .home-wrapper .founding-artisans .inner .holder h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
  }

  .owl-carousel .owl-item {
    background-color: white !important;
    border: none !important;

    &.active {
      background-color: white !important;
    }
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
    margin-top: 22px;
  }

  .home-wrapper .how-to-platform .inner .platform-card .cards p {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .home-wrapper .banner img {
    height: 300px;
  }

  .home-wrapper .inner-one .left-side .left-side-holder {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
    text-align: center;
    display: block;
    max-width: 100%;
  }

  .home-wrapper .inner-one {
    margin-top: 52px;
  }

  .left_sede_block_text {
    height: 480px;
    overflow: hidden;

    &.opened {
      height: max-content;
      overflow: auto;
    }
  }

  .home-wrapper .inner-one .left-side .left-side-text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .founding_opened1 {
    margin-bottom: 30px;
  }

  .home-wrapper .inner-one .right-side img {
    height: 335px !important;
  }

  .home-wrapper .how-to-platform .inner .holder .text {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
    max-width: 450px;
  }

  .read-more-link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #000000;
  }

  .home-wrapper .inner-one .inner-two .left-side-holder {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 16px;
  }

  .home-wrapper .banner-two.third img {
    width: 100%;
    height: 100%;
  }

  .home-wrapper .banner-two {
    height: 150px;
    width: 100%;
  }

  .home-wrapper .founders .founder-holder {
    margin: 72px 0 40px 0;
  }

  .home-wrapper .founders .founder-holder .founder-holder-text {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #000000;
  }

  .home-wrapper .founders::before {
    font-size: 28px;
    line-height: 39px;
  }

  .home-wrapper .founders img {
    height: 171px;
  }

  .card_two_carousel {
    display: block;
  }

  .home-wrapper .our-team .inner .card-two .inner-card {
    width: 100%;
  }

  .card_two_nocaro {
    display: none !important;
  }

  .team-wrapper .top-place img {
    width: 100% !important;
    height: 100% !important;
    margin: auto;
  }

  .team-wrapper .top-place {
    width: 240px !important;
    height: 240px !important;
    margin: 0 auto 39px auto;
  }

  .inner_div_btn_block_1 {
    margin-top: 0;
  }

  .home-wrapper .banner-two.four img {
    height: 171px;
  }

  .home-wrapper .banner-two.five img {
    height: 171px !important;
  }

  .home-wrapper .banner-two.four::before {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 39px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #fffdfd;
  }

  .home-wrapper .banner-two.five::before {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 39px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #fffdfd;
  }
}
