.tour-wrapper{
    margin: 100px 0;
    h1{
        margin-bottom: 40px;
        font-family: Vollkorn;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 140%;
        letter-spacing: 0.065em;
        color: #242424;
    }
    .image1{
        width: 100%;
        height: 600px;
        border-radius: 20px;
    }
    .image2{
        width: 45%;
        height: 400px;
        margin-top: 40px;
    }
    .tour-next{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    p{
        font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.0216em;
    color: #000000;
    margin-top: 48px;
    width: 50%;
}
h6{
    font-family: Montserrat;
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 36px;
letter-spacing: 0.0216em;
color: #000000;
margin-top: 48px;
}
}