.shop_single_page_wrapper {
  .bread {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #9f9f9f;
    .black_txt {
      color: black;
      font-weight: bold;
    }
  }

  .product_information {
    margin: 0 108px;
    margin-top: 32px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .product_left_side {
      width: 46%;
      position: relative;
      button {
        position: absolute;
        z-index: 1;
        right: 28px;
        top: 36px;
        border: none;
        background-color: transparent;
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        .love {
          width: 40px;
          height: 36px;
          fill: transparent;
        }
      }
    }
    .product_right_side {
      width: 47%;
      .item_name_link {
        margin-bottom: 24px;
        span {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15em;
          color: #797979;
          display: block;
          margin-bottom: 25px;
          text-align: justify;
        }
        .artisans_link {
          border: 1px solid #000;
          display: inline-block;
          padding: 10px;
          border-radius: 10px;
          color: #333;
          font-size: 18px;
        }
      }
      p {
        font-family: Vollkorn;
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 130%;
        letter-spacing: 0.11em;
        color: #000000;
        margin-bottom: 8px;
        width: 100%;
      }
      .price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .sale {
          display: flex;
          align-items: flex-end;
          span {
            &:nth-child(1) {
              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 48px;
              line-height: 150%;
              color: #000000;
              margin-right: 15px;
            }
            &:nth-child(2) {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 24px;
              line-height: 150%;
              text-decoration-line: line-through;
              color: #9e9e9e;
              margin-bottom: 10px;
            }
          }
        }
        h4 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: 0.15em;
          color: #000000;
          padding-left: 27px;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            left: 0;
            width: 16px;
            height: 12px;
            top: 0;
            bottom: 0;
            margin: auto;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../../../assets/icons/done_24px.png");
          }
        }
      }

      .color_size_count {
        margin-top: 54px;
        display: flex;
        align-items: flex-start;
        gap: 20px;
        justify-content: space-between;
        .count,
        .color,
        .size {
          span {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.1em;
            color: #000000;
          }
        }

        .count {
          .counter {
            display: flex;
            align-items: center;
            margin-top: 19px;
            button {
              width: 32px;
              height: 32px;
              background-color: transparent;
              border: none;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #000;
              cursor: pointer;
            }
            h6 {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              text-align: center;
              letter-spacing: 0.1em;
              color: #19191d;
              margin: 0 30px;
            }
          }
        }
        .color {
          .colors {
            .color_back_data {
              width: 30px;
              height: 30px;
              display: block;
              border-radius: 50%;
              margin-top: 18px;
              border: 1px solid #000;
              background-image: repeating-linear-gradient(
                red,
                yellow 10%,
                green 20%
              );
            }
            .multicolor {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 150%;
              color: #000000;
              margin-top: 19px;
              display: block;
            }
          }
        }

        .size {
          .sizes {
            display: flex;
            margin-top: 16px;
            button {
              border: none;
              background-color: transparent;
              width: 100%;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 8px;
              border: 1px solid #000000;
              box-sizing: border-box;
              border-radius: 16px;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              letter-spacing: 0.1em;
              color: #242424;
              cursor: pointer;
            }
          }
        }
      }
      .delivery_adress {
        margin-top: 32px;
        margin-bottom: 24px;
        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0.0216em;
          color: #000000;
          margin-bottom: 16px;
        }
        input {
          background: #f5f5f5;
          border: none;
          outline: none;
          border-radius: 8px;
          width: 100%;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0.0216em;
          color: #000;
          padding: 15px 10px 15px 22px;
          &:placeholder {
            color: #ababab;
          }
        }
      }

      .add_card_btn {
        border: none;
        border-radius: 8px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.05em;
        color: #ffffff;
        background-color: #000;
        width: 100%;
        padding: 12px 0;
        display: block;
        margin-bottom: 19px;
        border: 1px solid transparent;
        &:active {
          background-color: #fff;
          border: 1px solid #000;
          color: #000;
        }
      }
      h2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: #000000;
        span {
          font-weight: 300;
          letter-spacing: 0.0216em;
        }
      }

      .comfortable {
        margin-top: 19px;
        display: flex;
        align-items: center;
        button {
          border: none;
          width: 121px;
          height: 100px;
          border: 1px solid #000000;
          background-color: transparent;
          box-sizing: border-box;
          border-radius: 16px;
          margin-right: 16px;
          span {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #7d7d7d;
            display: block;
          }
          h3 {
            font-family: Vollkorn;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            height: 67px;
            line-height: 140%;
            letter-spacing: 0.065em;
            color: #000000;
          }
        }
        margin-bottom: 67px;
      }
      .description {
        h2 {
          font-family: Vollkorn;
          font-style: normal;
          font-weight: 800;
          font-size: 36px;
          line-height: 50px;
          letter-spacing: 0.3em;
          color: #000000;
          margin-bottom: 32px;
        }
        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 36px;
          letter-spacing: 0.0216em;
          color: #161616;
        }
      }
    }
  }
  .related_items {
    margin-top: 96px;
    margin-bottom: 268px;
    h1 {
      font-family: Vollkorn;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 50px;
      letter-spacing: 0.3em;
      text-align: center;
      color: #000000;
      margin-bottom: 48px;
    }
    .card {
      width: 23%;
    }
    .related_item_card {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
