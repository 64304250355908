.button_select {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  letter-spacing: 0.018em;
  color: #242424;
  outline: none;
  border: 1px solid #f2f2f2;
  padding: 5px;
  border-radius: 5px;
  &:last-child {
    margin-left: 30px;
  }
}

.customer_table {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  height: 149px;

  &.active {
    height: 0;
    overflow: hidden;
    border-top: 1px solid #ff7575;
    border-bottom: 1px solid #ff7575;
  }

  .table_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 24px;
    position: relative;
    z-index: 999;
    background-color: #fff;
    transition: 0.2s;

    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #000000;
    }

    .product {
      width: 35%;
      text-align: center;
    }

    .price {
      width: 16%;
      text-align: center;
    }

    .id {
      width: 16%;
      text-align: center;
    }

    .date {
      width: 16%;
      text-align: center;
    }

    .info {
      width: 16%;
      text-align: center;
    }

    &.active {
      transform: translateX(-100px);
      z-index: 100;
      position: relative;
    }
  }

  .remove {
    background-color: #ff7575;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;

    button {
      border: none;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

  .table_card {
    display: flex;
    align-items: center;
    padding-top: 24px;

    h4 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: #000000;
    }

    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      color: #19191d;
      text-align: left;
      margin-bottom: 20px;
    }

    .img {
      margin-right: 24px;
      min-width: 154px;
      height: 100px;
      border-radius: 8px;
      object-fit: cover;
    }

    button {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.018em;
      color: #242424;
      padding: 2px 5px;
      background-color: transparent;
      border: none;
      border-radius: 5px;
      border: 1px solid #333;

      &:nth-child(1) {
        display: block;
        margin-right: 15px;
      }
    }

    .buttons {
      display: flex;
      align-items: flex-start;
    }

    .product {
      display: flex;
      align-items: flex-start;
    }

    .price {
      h4 {
        color: #ff7575;
      }
    }

    .date {
      display: flex;
      justify-content: center;

      .counter {
        display: flex;
        align-items: center;
        // margin-top: 19px;

        button {
          width: 32px;
          height: 32px;
          background-color: transparent;
          border: none;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #000;
          cursor: pointer;
        }

        h6 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.1em;
          color: #19191d;
          margin: 0 30px;
        }
      }
    }

    .id {
      h4 {
        color: #ff7575;
      }
    }

    .info {
      display: flex;
      justify-content: center;

      button {
        display: block;
        border: none;
        background-color: transparent;
      }
    }
  }
}

@media screen and (max-width: "1100px") {
  .customer_table {
    .table_card {
      .img {
        margin-right: 10px;
      }
      .info {
        width: 5%;
      }
      .id {
        width: 10%;
      }
      .price {
        width: 14%;
      }
      .date {
        width: 14%;
      }
    }
  }
}
@media screen and (max-width: "950px") {
  .customer_table {
    .table_card {
      h4 {
        font-size: 16px;
      }
      .img {
        margin-right: 10px;
      }
      .info {
        width: 5%;
      }
      .id {
        width: 10%;
      }
      .price {
        width: 14%;
      }
      .date {
        width: 14%;
      }

      .img {
        min-width: 100px;
        object-fit: cover;
      }
      h3 {
        font-size: 13px;
        line-height: 20px;
        font-weight: lighter;
        margin-bottom: 7px;
      }

      .date {
        .counter {
          margin-top: 0;
          button {
            width: 25px;
            height: 25px;
            margin: 0;
            img {
              width: 10px;
            }
          }
          h6 {
            margin: 0 15px;
          }
        }
      }
    }
  }

  .button_select {
    padding: 0;
    &:last-child {
      margin-left: 10px;
    }
  }
}
