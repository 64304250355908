.news_card {
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  display: block;

  border: 0.5px solid transparent;

  .card_top {
    width: 100%;
    height: 340px;
    margin-bottom: 32px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .news_content {
    box-sizing: border-box;
    .card_name {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: #242424;
      margin-bottom: 0;
      margin-bottom: 11px;
      margin-top: 30px;
    }
    .card_set {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      span {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #393939;
      }
      .card_cate {
        display: flex;
        align-items: flex-start;
        span {
          &.Category {
            display: block;
            padding-right: 10px;
            color: #797979;
          }
        }
      }
    }


    .threedots {
      display: block;
      border: none;
      background: none;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: #000000;
      padding-bottom: 5px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
        .wrapper_p {
            height: 160px;
            overflow: hidden;
            
            p {
                font-family: Inter;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #797979;
                margin: 0;
    
            }
        }
    
        .opened {
            height: max-content !important;
            overflow: auto;
            font-family: Inter;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #797979;
                margin: 0;
        }
@media only screen and (max-width: 700px) {
  .news_card .card_top {
    height: 222px;
  }

  .news_card .news_content p {
    font-size: 17px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 450px) {
}
