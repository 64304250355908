.popular_post_wrapper{
    display: flex;
    // align-items: center;
    width: 100%;
    margin-bottom: 24px;
    background: #FFFFFF;
    border: 0.5px solid #9CA5B2;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
    .right_side{
        padding: 0px 24px 0px 32px;
        box-sizing: border-box;
        width: 65%;
        .pop_text{
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 135%;
            color: #242424;
            margin: 0;
            margin-bottom: 14px;
            // min-width: 234px;
            // min-height: 72px;
            overflow: hidden;
        }
        .pop_date{
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #797979;
        }
    }
    .left_side{
        width: 35%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


@media only screen and (max-width:1024px) {
    
     .popular_post_wrapper .right_side {
        padding: 10px;
    }

     .popular_post_wrapper .left_side img {
        height: 100%;
    }

     .popular_post_wrapper {
         align-items: inherit;
     }
}



@media only screen and (max-width:450px) {


    .popular_post_wrapper .right_side .pop_text {
        font-size: 18px;
line-height: 135%;
    }

    .popular_post_wrapper .right_side {
        padding: 10px 20px;
    }
}