@mixin content-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.0216em;
    color: #000000;
}


@mixin block-name {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.1em;
    color: #000000;
}

.youtube-card {
    margin-bottom: 132px;

    .left-side {
        h2 {
            @include block-name();
            margin-bottom: 12px;
        }

        p {
            @include content-text()
        }
    }

    .right-side {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        overflow: hidden;
        h3{
            position: absolute;
            z-index: 3;
            color: #fff;
            font-family: Vollkorn;
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 50px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            top: 20px;
            left: 20px;
        }
        &::before{
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #000000;
            top: 0;
            left: 0;
            color: #fff;
            z-index: 1;
            right: 0;
            bottom: 0;
            margin: auto;
        }
        iframe {
            width: 100%;
            height: 404px;
            border-radius: 10px;
            overflow: hidden;
        }
    }
}

