.country_wrapper_pages {
  overflow: hidden;
  .banner {
    width: 100%;
    height: 267px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .page_hero {
    margin-top: 96px;

    .herp_text {
      font-family: Vollkorn;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 50px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 72px;
      position: relative;
      display: inline-block;
      &::before {
        position: absolute;
        content: "";
        width: 1000%;
        left: 97%;
        bottom: 16px;
        background-color: #000000;
        height: 3px;
      }
    }

    .two_side_block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 109px;

      .left_side {
        width: 48%;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 36px;
          letter-spacing: 0.0216em;
          color: #000000;
        }
      }

      .right_side {
        width: 48%;

        .animation_images {
          display: flex;
          height: 100%;
          width: 100%;
          height: 605px;

          .card_image {
            width: 23%;
            height: 100%;
            transition: 0.3s;

            &.active {
              width: 64%;
            }

            &:nth-child(2) {
              padding: 0 10px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              cursor: pointer;
            }
          }
        }
      }
    }

    .map_blocks {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 97px;

      .left_side {
        margin-right: 96px;
        position: relative;
        min-width: 256px;
        height: 256px;

        img {
          object-fit: cover;
          border-radius: 50%;
          width: 256px;
          height: 256px;
          position: absolute;
          left: -10px;
        }
      }

      .right_side {
        .text {
          margin-bottom: 0;
          margin-bottom: 32px;
          font-family: Vollkorn;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 50px;
          letter-spacing: 0.3em;
          text-transform: uppercase;
          color: #000000;
        }

        .more_text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 36px;
          letter-spacing: 0.0216em;
          color: #000000;
          margin-bottom: 0;
          margin-bottom: 35px;
        }

        .text_btn {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.105em;
          text-decoration-line: underline;
          color: #000000;
          background-color: transparent;
          border: none;
          padding-left: 43px;
          position: relative;

          &::before {
            position: absolute;
            content: "";
            background-repeat: no-repeat;
            width: 20px;
            height: 28px;
            background-size: contain;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-image: url("../../assets/icons/Vector.png");
          }
        }
      }
    }
  }

  .what_the_fuck {
    margin-top: 96px;

    h1 {
      font-family: Vollkorn;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 50px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      color: #000000;
      display: flex;
      justify-content: flex-end;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 1000%;
        right: 60%;
        height: 3px;
        bottom: 16px;
        background-color: #000000;
      }
    }
  }

  .craft_schools {
    margin-top: 95px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    position: relative;
    .btns_of_slider {
      display: flex;
      align-items: center;
      .slider_btn_prev {
        position: absolute;
        left: -30px;
        top: 147px;
        z-index: 999;
        background-color: unset;
        border: 0;
        transform: rotate(180deg);
      }
      .slider_btn_next {
        position: absolute;
        top: 147px;
        right: -30px;
        z-index: 999;
        background-color: unset;
        border: 0;
      }
    }

    .slider_card {
      width: 100%;
      margin: 0 auto;
    }
    .map_views {
      display: block;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.105em;
      text-decoration-line: underline;
      color: #000000;
      background-color: transparent;
      border: none;
      margin: 0 auto;
      margin-top: auto;
      padding-left: 43px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        background-repeat: no-repeat;
        width: 20px;
        height: 28px;
        background-size: contain;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: url("../../assets/icons/Vector.png");
      }
    }

    .craft_school_card {
      display: flex;
      flex-direction: column;
      align-items: center;
      .slider_card {
        width: 30%;
      }

      img {
        width: 374px;
        height: 374px;
        object-fit: cover;
        border-radius: 50%;
        margin: 0 auto;
      }

      h2 {
        font-family: Vollkorn;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 50px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.3em;
        color: #000000;
        margin: 0;
        margin: 72px 0 27px 0;
      }

      p {
        font-family: Montserrat;
        margin-bottom: 20px;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.0216em;
        color: #000000;
        text-align: justify;
      }
    }
  }
}
.inner_img {
  img {
    height: 636px;
    object-fit: cover;
  }
}
.mobile_image_slider {
  display: none;
}
.yandex_map_info {
  width: 100%;
  height: 940px;
  div {
    width: 100% !important;
    height: 940px !important;
    div {
      width: 100% !important;
      height: 940px !important;
      div {
        width: 100% !important;
        height: 940px !important;
      }
    }
  }
}

.category_buttons {
  display: flex;
  align-items: center;
  margin-top: 30px;
  button {
    width: 224px;
    height: 160px;
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: 36px;
    border: none;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    transition: 0.2s;
    &:hover {
      background: #505050;

      span {
        color: #fff;
      }

      .icons {
        fill: #fff;
      }
    }
    &.active {
      background: #505050;

      span {
        color: #fff;
      }

      .icons {
        fill: #fff;
      }
    }
    .icons {
      width: 58px;
      height: 58px;
      fill: #383838;
    }
    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: #242424;
      display: block;
      margin-top: 18px;
    }
  }
}

.response_data {
  h1 {
    margin: 64px 0 36px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.1em;
    color: #242424;
  }
  .artisan_cards {
    display: flex;
    flex-wrap: wrap;
    .single_cards {
      width: 30%;
      margin: 0 2% 30px 1.3%;
    }
  }

  .no_data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    position: relative;
    h1 {
      color: rgb(110, 110, 110);
      width: 60%;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .country_wrapper_pages .craft_schools .slider_card {
    width: 100%;
  }
  .country_wrapper_pages .craft_schools .craft_school_card {
    padding: 32px;
  }
  .country_wrapper_pages .craft_schools .craft_school_card img {
    width: 256px;
    height: 256px;
  }
  .country_wrapper_pages .craft_schools .btns_of_slider .slider_btn_prev {
    left: 0;
    top: 95px;
  }
  .country_wrapper_pages .craft_schools .btns_of_slider .slider_btn_next {
    right: 0;
    top: 95px;
  }
}
@media only screen and(max-width:1024px) {
  .country_wrapper_pages .craft_schools .btns_of_slider .slider_btn_prev {
    display: none;
  }
  .country_wrapper_pages .craft_schools .btns_of_slider .slider_btn_next {
    display: none;
  }
  .country_wrapper_pages .page_hero .two_side_block .right_side {
    display: none;
  }
  .country_wrapper_pages .page_hero .two_side_block .mobile_image_slider {
    display: block;
    width: 307px;
  }
  .country_wrapper_pages
    .page_hero
    .two_side_blockx
    .right_side
    .animation_images {
    display: none;
  }
  .country_wrapper_pages .page_hero .two_side_block .left_side {
    width: 66%;
  }
  .country_wrapper_pages .craft_schools .craft_school_card img {
    width: 232px;
    height: 232px;
  }
}
@media only screen and (max-width: 700px) {
  .country_wrapper_pages .page_hero .two_side_block {
    flex-direction: column;
  }
  .country_wrapper_pages .page_hero .two_side_block .left_side {
    width: 99%;
  }
  .country_wrapper_pages .page_hero .two_side_block .mobile_image_slider {
    display: none;
  }
  .country_wrapper_pages .page_hero .two_side_block .right_side {
    display: block;
    width: 100%;
    margin-top: 40px;
  }
  .country_wrapper_pages .page_hero .map_blocks {
    flex-direction: column;
  }
  .country_wrapper_pages .page_hero .map_blocks .left_side img {
    margin-bottom: 40px;
  }
  .country_wrapper_pages .page_hero .map_blocks .right_side .text {
    margin-top: 41px;
    text-align: center;
  }
  .country_wrapper_pages .what_the_fuck h1 {
    text-align: right;
  }
  .country_wrapper_pages .what_the_fuck h1::before {
    right: 28%;
  }
  .country_wrapper_pages .page_hero .map_blocks .left_side {
    margin-left: auto;
    margin-right: auto;
  }

  .country_wrapper_pages .page_hero .two_side_block .mobile_image_slider {
    display: none;
  }
  .country_wrapper_pages
    .page_hero
    .two_side_blockx
    .right_side
    .animation_images {
    display: block;
  }
}
@media only screen and (max-width: 450px) {
  .country_wrapper_pages .page_hero .two_side_block .mobile_image_slider {
    display: block;
    width: 100%;
  }
  .country_wrapper_pages .page_hero .two_side_block .right_side {
    display: none;
  }
  .country_wrapper_pages .page_hero .herp_text::before {
    display: none;
  }
  .country_wrapper_pages .what_the_fuck h1::before {
    display: none;
  }
  .country_wrapper_pages .page_hero .herp_text {
    font-size: 24px;
    line-height: 40px;
    text-align: center;
  }
  .country_wrapper_pages .page_hero .two_side_block .left_side p {
    font-size: 17px;
    line-height: 32px;
  }
  .country_wrapper_pages .page_hero .map_blocks .right_side .text {
    font-size: 24px;
    line-height: 40px;
    text-align: center;
  }
  .country_wrapper_pages .page_hero .map_blocks .right_side .more_text {
    font-size: 17px;
    line-height: 32px;
  }
  .country_wrapper_pages .what_the_fuck h1 {
    font-size: 24px;
    line-height: 40px;
    text-align: center;
  }
  .country_wrapper_pages .craft_schools .craft_school_card {
    padding: 21px;
  }

  .country_wrapper_pages .page_hero .two_side_block .mobile_image_slider {
    display: block;
    width: 100%;
  }
  .country_wrapper_pages
    .page_hero
    .two_side_blockx
    .right_side
    .animation_images {
    display: none;
  }

  .country_wrapper_pages
    .page_hero
    .two_side_blockx
    .right_side
    .animation_images {
    height: 407px;
  }
}

.yandex_map_wrapper {
  height: 700px;
  width: 100%;
  overflow: hidden;
  & > div {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .response_data {
    .artisan_cards {
      .single_cards {
        width: 45%;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .response_data {
    .artisan_cards {
      .single_cards {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .category_buttons {
    button {
      width: 100px;
      height: 60px;
      span {
        font-size: 13px;
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .category_buttons {
    width: 100%;
    overflow: scroll;
    button {
      min-width: 100px;
      min-height: 60px;
      span {
        font-size: 13px;
        margin-top: 0;
      }
    }
  }
}
