.Category_wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    button{
        background-color: transparent;
        border: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-align: left;
        color: #292929;
        cursor: pointer;
    }
    span{
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #292929;
        background: #F3F3F3;
        border-radius: 5px;
    }
}