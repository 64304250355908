.contact_page{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .contact_page_left{
        width: 40%;
        padding: 50px;
        background: #FFFFFF;
        border: 1px solid grey;
        border-radius: 40px 0px 0px 40px;
        margin-top: 50px;
        margin-bottom: 50px;
        border-right: 0;
        .item_contact{
            .title_contact{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.05em;
                color: #000000;
                position: relative;
                padding-bottom: 5px;
            }
            .text_contact{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.1em;
                color: rgb(124, 124, 124);
                margin: 8px 0;
                display: inline-block;
                position: relative;
                padding-bottom: 1px;
                text-decoration: none;
                margin-bottom: 30px;
            }
        }
    }
    .contact_page_right{
        margin-top: 50px;
        margin-bottom: 50px;
        background: #FFFFFF;
        border: 1px solid grey;
        border-radius: 40px 40px 40px 0px ;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 45px 64px 40px 64px ;
        .contact_page_right_item{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 22px;
            letter-spacing: 0.05em;
            color: #000000;
            position: relative;
            padding-bottom: 5px;
            margin-bottom: 36px;

        }
        label{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.05em;
            color: #000000;
            position: relative;
            padding-bottom: 5px;
            margin-bottom: 36px;
            input{
                border-radius: 5px;
                width: 360px;
                border: 1px solid grey;
                padding: 19px 0px 19px 20px;
                margin-top: 10px;
                &:focus{
                    outline: none;
                }
                &.require {
                    border: 1px solid red;
                }

            }
            textarea{
                border-radius: 5px;
                width: 360px;
                border: 1px solid grey;
                padding: 19px 0px 19px 20px;
                margin-top: 10px;
                height: 166px;
                &:focus{
                    outline: none;
                }
                &.require {
                    border: 1px solid red;
                }

            }

        }
        .send_btn{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            background: 0 0;
            cursor: pointer;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: .2px;
            padding: 15px 33px;
            background:  black;
            box-shadow: 0 4px 31px rgb(0 165 239 / 17%);
            border-radius: 8px;
            color: #fff;
            border: 1px solid transparent;
            transition: all .3s ease;
            &:hover{
                border: 1px solid black;
                background: #fff;
                color:  black;
            }
        }
    }


}
@media only screen and(max-width:968px)  {
    .contact_page .contact_page_right{
        padding: 25px 30px 20px 30px;
    }
    .contact_page .contact_page_left{
        padding: 30px;
    }
}
@media only screen and (max-width:700px) {
    .contact_page{
        flex-direction: column;
        align-items: center;
    }
    .contact_page .contact_page_left{
        border-right: 1px solid grey;
        border-radius: 40px;
        width: 422px;
    }

}
@media only screen and (max-width:450px) {
    .contact_page .contact_page_right label input{
        width: 280px;
    }
    .contact_page .contact_page_right label textarea{
        width: 280px;
    }
    .contact_page .contact_page_right .contact_page_right_item{
        font-size: 24px;
    }
    .contact_page .contact_page_left{
        width: 87%;
    }
    .contact_page .contact_page_right{
        width: 87%;
    }
}
@media only screen and(max-width:400px) {
    .contact_page .contact_page_right label input{
        width: 100%;
    }
    .contact_page .contact_page_right label textarea{
        width: 100%;
    }
    
}