.profile_wrapper {
  .bread {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #9f9f9f;
    margin-bottom: 59px;
    span {
      color: black;
      font-weight: bold;
    }
  }

  .non_profile{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    button{
      padding: 5px 20px;
      display: inline-block;
      color: #000;
      background-color: transparent;
      border: 2px solid #000;
      border-radius: 7px;
      font-size: 18px;
      &:active{
        background-color: #000;
        color: #fff;
      }
    }
  }

  .profile_hidden{
    text-align: center;
    font-family: Vollkorn;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: 0.11em;
    color: #7a7a7a;
    margin-bottom: 40px;
  }

  .not_order {
    text-align: center;
    font-family: Vollkorn;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: 0.11em;
    color: #7a7a7a;
  }

  .customer_info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .left_side {
      display: flex;
      align-items: flex-start;
      img {
        width: 176px;
        height: 176px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 36px;
      }
      .customer_name {
        h2 {
          font-family: Vollkorn;
          font-style: normal;
          font-weight: 800;
          font-size: 40px;
          line-height: 56px;
          letter-spacing: 0.11em;
          color: #242424;
        }
        span {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.1em;
          color: #000000;
        }
      }
    }
    .right_side {
      display: flex;
      flex-direction: column;
      .link {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: right;
        letter-spacing: 0.15em;
        color: #9a9a9a;
        margin-bottom: 14px;
        display: block;
        border: none;
        background-color: transparent;
      }
    }
  }

  .customer_table {
    margin-top: 96px;
    &.done{
      .info {
          .done {
            color:#219F53;
          }
          .error{
            display: none;
          }
      }
    }
    &.error{
      .info {
        .done {
          display: none;
        }
        .error{
          color: #FF7575;
        }
    }
    }
    .table_holder,
    .table_card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 24px;
      span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
      }
      .product {
        width: 35%;
        text-align: center;
      }
      .id {
        width: 15%;
        text-align: center;
      }
      .price {
        width: 20%;
        text-align: center;
      }
      .date {
        width: 15%;
        text-align: center;
        .copy{
          width: 100%;
          display: flex;
          .button{
              background-color: transparent;
              border: none;
              width: 30px;
              height: 30px;
              padding-right: 10px;
              position: relative;
              &:active{
                  transform: scale(0.9);
              }
          }
          input{
              border: none;
              background-color: transparent;
              cursor: pointer;
          }
      }
      }
      .info {
        width: 25%;
        text-align: center;
      }
    }
    .table_card {
      display: flex;
      align-items: center;
      padding-top: 24px;
      h4,a {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: #000000;
      }
      a{
        color: #00a4ef;
      }
      h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.05em;
        color: #19191d;
        text-align: left;
        margin-bottom: 20px;
      }
      .image {
        margin-right: 24px;
        min-width: 154px;
        height: 100px;
        border-radius: 8px;
        object-fit: cover;
      }
      button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.018em;
        color: #242424;
        padding: 2px 5px;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        border: 1px solid #333;
        &:nth-child(1) {
          display: block;
          margin-right: 15px;
        }
      }
      .buttons {
        display: flex;
        align-items: flex-start;
      }
      .product {
        display: flex;
        align-items: flex-start;
      }
      .price {
        h4 {
          color: #ff7575;
        }
      }
      
    }
  }
  .banner {
    width: 100%;
    height: 128px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 200px;
  }
}
.user_image_mobile {
  display: none;
}
.product_mobile {
  display: none;
}
.table_wrapper_mobile {
  .table_holder_mobile {
    display: none;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 24px;
    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #000000;
    }
    .product {
      width: 35%;
      text-align: center;
    }
    .price {
      width: 16%;
      text-align: center;
    }
    .id {
      width: 16%;
      text-align: center;
    }
    .date {
      width: 16%;
      text-align: center;
    }
    .info {
      width: 25%;
      text-align: center;
    }
  }
}
.empty_shop{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 100px 0;
  img{
      width: 136px;
      height: 136px;
      object-fit: contain;
      margin-bottom: 12px;
  }
  h1{
      font-family: Vollkorn;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 0.425em;
      margin-bottom: 41px;
      color: #8A8A8A;
  }
  .link{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.05em;
      color: #000000;
      display: block;
      padding: 12px 34px;
      border: 2px solid #000000;
      border-radius: 8px;
  }
}
@media only screen and (max-width: 968px) {
  .table_wrapper_mobile {
    .table_holder_mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .product {
        display: none;
      }
      .price {
        width: auto;
        text-align: center;
        margin-bottom: 21px;
      }
      .id {
        width: auto;
        text-align: center;
        margin-bottom: 21px;
      }
      .date {
        width: auto;
        text-align: center;
        margin-bottom: 21px;
      }
      .info {
        width: auto;
        text-align: center;
        margin-bottom: 21px;
      }
    }
  }
  .profile_wrapper .customer_table .table_holder {
    display: none;
  }
  .table_wrapper_mobile {
    display: flex;
    justify-content: space-between;
  }
  .profile_wrapper .customer_table .table_card {
    flex-direction: column;
  }
  .profile_wrapper .customer_table .table_card {
    padding-top: 0;
    align-items: flex-end;
    .product {
      display: none;
      margin-bottom: 21px;
    }
    .price {
      width: max-content;
      margin-bottom: 21px;
    }
    .id {
      width: max-content;
      margin-bottom: 21px;
    }
    .date {
      width: max-content;
      margin-bottom: 21px;
    }
    .info {
      width: max-content;
      margin-bottom: 21px;
    }
  }
  .table_wrapper_mobile_top {
    padding-top: 24px;
    h4 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: #000000;
    }
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      color: #19191d;
      text-align: left;
      margin-bottom: 20px;
    }
    img {
      margin-right: 24px;
      width: 154px;
      height: 100px;
      border-radius: 8px;
      object-fit: cover;
    }
    button {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.018em;
      color: #242424;
      padding: 2px 5px;
      background-color: transparent;
      border: none;
      border-radius: 5px;
      border: 1px solid #333;
      &:nth-child(1) {
        display: block;
        margin-right: 15px;
      }
    }
    .buttons {
      display: flex;
      align-items: flex-start;
    }
    .product_mobile {
      max-width: 558px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;
    }
  }
  .profile_wrapper .customer_info .right_side {
    width: 100%;
    margin-top: 18px;
  }
}
@media only screen and (max-width: 700px) {
  .profile_wrapper .customer_info .left_side img {

    display: none;
  }
  .user_image_mobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 123px;
    margin-bottom: 24px;
    img {
      width: 123px;
      height: 123px;
      border-radius: 50%;
    }
  }
}


@media only screen and (max-width: 450px) {
  .profile_wrapper .customer_info .left_side .customer_name h2 {
    font-size: 32px;
    line-height: 45px;
  }
  .table_wrapper_mobile_top img {
    width: 109px;
    height: 71px;
  }
}
