.slide_card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  border: 0.5px solid #9ca5b2;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  .info {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .left_info {
      width: 136px;
      height: 136px;
      margin-right: 16px;

      img {
        width: 136px;
        height: 136px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .right_info {
      width: 100%;
      h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        letter-spacing: 0.1em;
        color: #242424;
        margin-bottom: 4px;
        word-break: break-word;
        height: 80px;
        text-align: center;
        // max-height: 80px;
      }

      span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: #979797;
        display: inline;

        &.fuck {
          color: #161616;
        }
      }

      .follow {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        button {
          border: none;
          background-color: transparent;
          border: 1px solid #161616;
          border-radius: 8px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.05em;
          color: #000000;
          padding: 0;
          width: 100px;
          margin: 0;
        }
      }
    }
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.0216em;
    color: #161616;
    height: 65px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .images {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    img {
      margin-bottom: 14px;
      width: 45%;
      height: 136px;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .button {
    display: block;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #ffffff;
    background: #000000;
    border-radius: 8px;
    padding: 12px 0;
    width: 100%;
    margin-top: auto;
    text-align: center;
  }
}

@media screen and (max-width: 1300px) {
  .slide_card {
    padding: 15px;
    .info {
      margin-bottom: 14px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .right_info {
        h3 {
          height: auto;
        }
        span {
          display: inline;
        }
        .follow {
          width: 100%;
        }
      }
    }
  }
}
