.craft_story_wrapper{
    .page_banner{
        width: 100%;
        height: 128px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 121px;
        &::before{
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.467);
            width: 100%;
            height: 100%;
            content: '';
        }
        h1{
            font-family: Vollkorn;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 50px;
            text-align: center;
            letter-spacing: 0.4em;
            text-transform: uppercase;
            color: #FFFFFF;
            z-index: 1;
        }
    }
    .category_buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 64px;
        button{
            border: none;
            background-color: transparent;
            border: 1px solid #000000;
            border-radius: 16px;
            padding: 16px 32px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.1em;
            color: #000000;
            cursor: pointer;
            transition: 0.2s;
            &:hover{
                background-color: #000;
                color: #fff;
            }
            &.active{
                color: #fff;
                background-color: #000;
            }
        }
    }
    .page_main_content_card_wrapper{
        .cards{
            margin-bottom: 96px;
        }
    }
}

@media screen and (max-width: 1360px) {
    .craft_story_wrapper{
        .page_main_content_card_wrapper{
            .cards{
                .CaseCard_wrapper{
                    display: none !important;
                }
            }
        }
    }
}

@media only screen and (max-width:1024px) {

    .craft_story_wrapper .category_buttons {
        justify-content: center;
    }

    .craft_story_wrapper .category_buttons button {
        margin: 0 10px;
    }
}


@media only screen and (max-width:818px) {

    .craft_story_wrapper .category_buttons button {
        padding: 12px 20px;
        font-size: 18px;
    }
    
}


@media only screen and (max-width:678px) {


    .craft_story_wrapper .category_buttons {
        width: max-content;

    }

    .craft_story_wrapper .category_buttons button {
        padding: 10px 18px;
        font-size: 18px;
    }
    
     .containerScrool {
         overflow: scroll;
         &::-webkit-scrollbar {
             display: none;
         }
     }
}


@media only screen and (max-width:450px) {

    .craft_story_wrapper .category_buttons button {
        padding: 8px 16px;
        font-size: 16px;
    }
}
