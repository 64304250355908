* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
div,
ul,
li,
a,
span {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.container {
  max-width: 1300px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 auto;
}

.back-to-top {
  width: 64px;
  height: 64px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slider {
  .slick-slide {
    margin: 0 9px;
  }
}

html {
  scroll-behavior: smooth;
}

.btn_back {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.btn_item {
  margin-left: 20px;
  margin-right: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  background: 0 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  padding: 15px 33px;
  background: black;
  box-shadow: 0 4px 31px rgb(0 165 239 / 17%);
  border-radius: 8px;
  color: #fff;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid black;
    background: #fff;
    color: black;
  }
}
.leaflet-marker-icon {
  color: white;
  white-space: nowrap;
}

.leaflet-tile {
  filter: hue-rotate(45deg) !important;
}
.asyncpaginatemodal {
  width: 304px;
  margin-bottom: 20px;
}
.asynpaginatecustom > div {
  min-height: 45px;
  min-width: 200px;
  border-color: #9ca5b2;
}

@media screen and (max-width: 1024px) {
  .App {
    &::before {
      width: 100%;
      height: 100%;
      background-color: #000;
      z-index: 1;
      content: "";
      position: fixed;
      opacity: 0.8;
      display: none;
    }
    &.active {
      &::before {
        display: block;
      }
    }
  }
}
