.team-wrapper {
    .top-place {
        margin-bottom: 33px;

        img {
            box-shadow: rgba(0, 0, 0, 1) 0px 3px 8px;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            filter: grayscale(100%);

        }
    }

    .contents {
        text-align: center;

        h3 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.1em;
            color: #000000;
            margin-bottom: 57px;
        }

        p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            color: #000000;
        }
    }
}


@media only screen and (max-width: 1400px) {

    .team-wrapper .top-place img {
        width: 100%;
        height: 100%;
    }

    .team-wrapper .contents h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
        min-height: 60px;
    }

    .team-wrapper .contents p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.0216em;
        color: #000000;
    }

    .home-wrapper .banner-two.five img {
        height: 399px;
    }
}

@media only screen and (max-width: 1024px) {

    .team-wrapper .top-place img {
        width: 275px;
        height: 275px;
    }

    .team-wrapper .contents h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
    }

    .team-wrapper .contents p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.0216em;
        color: #000000;
    }
}

@media only screen and (max-width: 968px) {

    .team-wrapper .top-place img {
        width: 280px;
        height: 280px;
    }

    .team-wrapper .contents h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
    }

    .team-wrapper .contents p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.0216em;
        color: #000000;
    }
}

@media only screen and (max-width: 700px) {

   .team-wrapper .top-place img {
        width: 240px;
        height:240px;
    }

   

    .team-wrapper .contents h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 18px;
    }

    .team-wrapper .contents p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.0216em;
        color: #000000;
    }
}

@media only screen and (max-width: 450px) {

    .team-wrapper .top-place img {
        width: 280px;
        height: 280px;
    }

    .team-wrapper .contents h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
    }

    .team-wrapper .contents p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.0216em;
        color: #000000;
    }
}