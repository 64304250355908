._-LJ2W{
    margin-top: 10px;
}
._3q7r8{
    background-color: transparent;
    height: 614px;
}
._1eGao{
    border-radius: 16px;
    overflow: hidden;
}

._-cAh3{
    min-width: 110px;
    border-radius: 8px;
    overflow: hidden;
    img{
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }
}

._2c50p{
    min-height: 110px;
}