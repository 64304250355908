.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: inset 0px 0px 68px rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(36px);
  top: 0;
  left: 0;
  z-index: 999;
}

.modal_wrapper {
  height: 95vh;
  overflow: auto;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 100px -50px black;
  backdrop-filter: blur(36px);

  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  @media only screen and (min-height: 830px) {
    background-color: white;
  }
}
.xbutton {
  background-color: transparent;
  outline: none;
  border: none;
  transform: translate(90rem);
  margin-top: 50px;
}

@media only screen and (max-width: 600px) {
  .xbutton {
    background-color: transparent;
    outline: none;
    border: none;
    transform: translate(30rem);
    margin-top: 200px;
  }
}
