.shop_card_wrapper {
  width: 100%;
  border: 0.5px solid #9ca5b2;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  .card_top {
    width: 100%;
    height: 300px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    button {
      border: none;
      width: 48px;
      height: 48px;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 16px;
      top: 16px;
      position: absolute;
      z-index: 1;
      &.active {
        display: none;
      }
      img {
        width: 26px;
        height: 24px;
        object-fit: contain;
      }
    }
    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: #ffffff;
      padding: 14px 36px;
      background: #000000;
      border-radius: 8px 0px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .card_content {
    padding: 24px;
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.05em;
      color: #242424;
      margin: 0;
      margin-bottom: 8px;
      display: -webkit-box;
      // max-width: 200px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .shipping {
      display: flex;
      align-items: center;
      margin-bottom: 27px;
      img {
        margin-right: 10px;
      }
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 30px;
        color: #242424;
      }
    }
    .price_delivry {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
      }
      .animate_btn {
        border: none;
        background-color: transparent;
        padding: 8px 24px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
        border-radius: 8px;
        border: 1px solid #000;
        transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
        &:active {
          transform: scale(0.9);
          background-color: darken(#000, 5%);
          color: #fff;
        }
      }
      .response_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &.active {
          flex-direction: column;
          align-items: flex-start;
          .price_sale {
            flex-direction: row;
            align-items: center;
          }
        }
        .sale {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 150%;
          text-decoration-line: line-through;
          color: #797979;
          margin-left: 10px;
          display: none;
          &.active {
            display: block;
          }
        }
      }
      .delete_btn {
        display: none;
      }
      .price_sale {
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;
      }

      &.active {
        align-items: flex-end;
        .delete_btn {
          border: none;
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: "1600px") {
  .shop_card_wrapper {
    .card_top {
      height: 200px;
    }
    .card_content {
      padding: 20px;
      .shipping {
        margin-bottom: 10px;
      }
      h3 {
        font-weight: 500;
      }
      .price_delivry {
        span {
          font-size: 22px;
        }
        .animate_btn {
          padding: 7px 20px;
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .shop_card_wrapper {
    .card_content {
      padding: 10px;
      .price_delivry {
        .response_block {
          flex-direction: column;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .shop_card_wrapper {
    .card_top {
      height: 281px;
    }
  }
}
