.blue{
    background-color: lightblue;
}

.green{
    background-color: green;
}

.brown{
    background-color: brown;
}

.darkblack{
    background-color: #000;
}

.yellow{
    background-color: yellow;
}