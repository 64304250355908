.settings_wrapper{
    padding: 20px 109px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 8px;
    overflow: hidden;
    width: 1200px;
    box-sizing: border-box;
    h1{
        font-family: Vollkorn;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 50px;
        letter-spacing: 0.3em;
        color: #000000;
        margin-bottom: 16px;
        text-align: center;
    }
    .form_holder{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        label{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.1em;
            color: #000000;
            display: block;
        }
        input{
            border: none;
            outline: none;
            padding: 3px 20px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 36px;
            letter-spacing: 0.0216em;
            color: #000000;
            display: block;
            width: 100%;
            width: 460px;
            background: #F8FAFC;
            border-radius: 4px;
            margin-bottom: 12px;
            &:placeholder-shown{
                color: #B8BCCA;
            }
        }
    }
    .holder{
        margin-top: 30px;
    }
    button{
        margin: 0 auto;
        background-color: transparent;
        display: block;
        margin-top: 20px;
        padding: 10px 70px;
        border-radius: 6px;
        border: 1px solid #000;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
    }
}
@media only screen and (max-width:1400px) {
    .settings_wrapper{
        width: 960px;
    }
    .settings_wrapper .form_holder input{
        width: 343px;
    }
    
}
@media only screen and (max-width:968px) {
    .settings_wrapper{
        width: 700px;
        padding: 34px 56px ;
    }
    .settings_wrapper .form_holder input{
        width: 280px;
    }
    .settings_wrapper button{
        margin-top: 0;
    }
    .settings_wrapper .holder{
        margin-top: 0;
    }
}
@media only screen and(max-width:700px) {
    .settings_wrapper{
        width: 100%;
        height: 606px;
        overflow: scroll;
    }
    
}
