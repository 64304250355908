.direction {
  padding: 8px 15px;
  border-radius: 5px;
  background: rgb(90, 212, 79);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  margin-top: 10px;
}

.see_more{
  padding: 8px 10px;
  display: inline-block;
  margin-top: 5px;
  border-radius: 5px;
  background-color: rgb(90, 212, 79);
  color: #fff !important;
}